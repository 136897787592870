.alert-banner {
    width: 680px;
    background: #0d5595;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    
    
    a {
        color: white;  
    }
}

.feed-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post {
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 32px;
    width: 680px;

    .post-info {
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
        display: flex;


        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin: 0px;
        }

        h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.025em;
            color: #66676B;
            margin: 0px;
        }
    }

    .post-content {
        white-space: pre-line;
        margin-top: 8px;
        p {
            padding-left: 16px;
            padding-right: 16px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.02em;
            text-align: left;
        }

        .photo-reveal {
            // max-height: 300px;
            // overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    .post-interaction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        padding-bottom: 8px;

        .like-container {
            display: flex;
            align-items: center;
        }

        button {
            height: min-content;
            margin-right: 8px;
        }

        p {
            font-size: 15px;
            line-height: 18px;
            color: #66676B;
            margin: 0px;
            padding: 0px;
        }
    }
}

.create-new-post {
    display: flex;
    align-items: center;
    background-color: white;
    width: 680px;
    border-radius: 8px;
    margin-bottom: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    padding-top:16px;
    padding-bottom:16px;

    img {
        margin-left: 16px;
    }

    .whats-on-mind {
        width: 586px;
        background-color: rgba(0,0,0,.05);
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        cursor: pointer;
        transition: all 150ms;
        color: #65676b;

        &:hover {
            background: #E5E6EB;
        }
    }
}

.create-post-header {
    padding-top: 16px;
    text-align: center;

}

.post-btn {
    margin-bottom: 32px;
    border: none;
    width: calc(100% - 48px);
    background: #1877f2;
    color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 150ms;

    &:hover {
        background-image: linear-gradient(rgba(0,0,0,.05), rgba(0,0,0,.05));
    }
}


.new-post-content {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    min-height: 300px;

    textarea {
        width: 100%;
        font-family: sans-serif;
        font-size: 18px;
        padding-top: 16px;
        border: none;
        resize: none;

        &:active {
            outline: none;
        }
        &:focus {
            outline: none;
        }
    }
}

.comment-heading {
    margin-top: 4px;
    margin-left: 16px;
    margin-bottom: 4px;
    padding: 0px;
}

.reply-box {
    margin-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;

    textarea {
        resize: none;
        border: none;
        width: calc(100% - 72px);
        background: rgba(0,0,0,.05);
        padding: 8px;
        font-family: sans-serif;

        &:focus {
            outline: none;
        }
    }

    .comment-btn {
        position: absolute;
        bottom: 16px;
        right: 16px;
        cursor: pointer;
        background: #1877f2;
        border: none;
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        transition: all 150ms;

        &:hover {
            background-image: linear-gradient(rgba(0,0,0,.05), rgba(0,0,0,.05));
        }    
    }
}

.div-line {
    width: 100%;
    height: 1px;
    background: rgba(0,0,0,.1);
}


.avatar {
    border-radius: 50%;
    filter: drop-shadow(1px 2px 2px rgba(0,0,0,.3));
    margin-right: 8px;
    height: 40px;
    width: 40px;
}

.to-whom {
    position: relative;

    h3 {
        cursor: pointer;
    }

    .selection-menu {
        position: absolute;
        background: white;
        border-radius: 8px;
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, .3);
        width: 340px;
        padding-top: 8px;

        p {
            padding: 0;
            margin: 0;
            font-size: 13px;
            padding-top: 8px;
            font-weight: bold;
            margin-bottom: 8px;
            padding-left: 16px;
        }

        h3 {
            padding: 12px 16px;
            transition: all 250ms ease;
            cursor: pointer;

            &:hover {
                background: #eee;
            }
        }
    }
    
}



.comment {
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: relative;
    margin-bottom: 4px;

    .comment-row {
        display: flex;

        .comment-bubble {
            background: rgba(0,0,0,.05);
            border-radius: 18px;
            width: 100%;
            margin-bottom: 16px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        }

        h1 {
            display: inline-block;
            width: fit-content;
            font-size: 13px;
            margin: 0px;
            padding: 0px;
            padding-left: 8px;
            padding-top: 8px;
        }

        p {
            margin: 0px;
            padding: 0px;
            padding-left: 8px;
            padding-bottom: 8px;
            line-height: 16px;
            font-size: 13px;
            white-space: pre-line;
        }
    }


    .comment-time {
        position: absolute;
        margin: 0;
        padding: 0;
        bottom: 0px;
        left: 54px;
        font-size: 12px;
        color: #66676B;
        display: flex;
        align-items: center;

        button {
            font-size: 12px;
            margin: 0px;
            padding: 0px;
            transform: translateY(-1px);
            color: #66676B;
        }

        .comment-like-btn {
            background: none;
            border: none;
        }

        .comment-like-text {
            display: none;
            margin: 0px;
            padding: 0px;
            margin-left: 4px;
        }

        .time-span {
            margin-left: 8px;
        }
    
    };

    .avatar {
        height: 32px;
        width: 32px;
    }
}

.comment-like-list {
    position: absolute;
    background: white;
    border-radius: 4px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .08), 0 2px 2px rgba(0, 0, 0, .15);
    // border: solid 1px rgba(0,0,0,.05);
    display: flex;
    flex-direction: column;
    min-width: 150px;
    top: 20px;
    left: 18px;
    z-index: 1;
    color: black;
    padding: 4px;
    padding-left: 4px;


    div {
        padding: 4px 8px;
    }
    .list-label {
        padding-bottom: 0px;
    }

}





@media (max-width: 900px) and (min-width: 300px) {
 
    .post, .create-new-post {
        width: 96%;

        .new-post-content {
            min-height: 150px;
            height: 150px;
            overflow: scroll;
        }

    }

    .whats-on-mind {
        width: 75% !important;
    }

    .feed-center {
        padding-bottom: 65px !important;
    }
}